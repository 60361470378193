
import { Component, Vue } from "vue-property-decorator";
import i18n from "../../plugins/i18n";
import ToastService from "../../services/helper/ToastService";
import Multiselect from "vue-multiselect";

import Person from "../../models/societymgmt/Person";
import SocietyService from "../../services/societymgmt/SocietyService";
import Society from "../../models/societymgmt/Society";

import StarterService from "../../services/sportevent/StarterService";
import Starter from "../../models/sportevent/Starter";
import SporteventCompetitionService from "../../services/sportevent/SporteventCompetitionService";
import SporteventCompetition from "../../models/sportevent/SporteventCompetition";
import PersonService from "../../services/societymgmt/PersonService";
import SporteventService from "../../services/sportevent/SporteventService";
import Competition from "@/models/sporteventCalculation/Competition";

@Component({
  components: {
    Multiselect,
  },
})
export default class StarterComponent extends Vue {
  public loading = true;
  public starter: Starter = new Starter();
  public allPersons: Person[] = [];
  public allSocieties: Society[] = [];
  public allSporteventCompetitions: SporteventCompetition[] = [];
  public savedCompetitions = 0;

  async mounted(): Promise<void> {
    this.allPersons = await PersonService.getAll();
    if (
      this.allPersons === null ||
      this.allPersons.length === 0 ||
      this.allPersons[0].firstname === undefined
    ) {
      this.allPersons = await PersonService.getAll(true);
    }

    this.allSocieties = await SocietyService.getAll();
    if (
      this.allSocieties === null ||
      this.allSocieties.length === 0 ||
      this.allSocieties[0].shortname === undefined
    ) {
      this.allSocieties = await SocietyService.getAll(true);
    }

    if (
      this.$route.params.id !== undefined &&
      this.$route.params.id !== "new"
    ) {
      StarterService.get(this.$route.params.id).then((item: Starter) => {
        this.starter = item;
        this.loadSporteventCompetitions(item.person);
      });
    } else {
      this.starter.sportevent =
        this.$store.state.localstore_sportevent.sportevent;
    }
    this.loading = false;
  }

  save(): void {
    this.loading = true;

    if (this.starter.sporteventCompetition.id === "all") {
      const allCompetitions = this.allSporteventCompetitions.filter(
        (sc) => sc.id !== "all" && sc.competition.isTeamCompetition === false
      );
      this.savedCompetitions = 0; // 1 = all
      allCompetitions.forEach((sc) => {
        if (sc.id !== "all") {
          this.starter.sporteventCompetition = sc;
          StarterService.save(this.starter).then((item: Starter) => {
            this.savedCompetitions++;
          });
        }
      });
      const interval = setInterval(() => {
        if (this.savedCompetitions === allCompetitions.length) {
          clearInterval(interval);
          this.handleBack();
        }
      }, 100);
    } else {
      StarterService.save(this.starter).then((item: Starter) => {
        if (item) {
          ToastService.Success(
            i18n.tc("labels.saved"),
            new Person(item.person).getFullname()
          );
          this.starter = item;
          this.handleBack();
        }
      });
    }
  }

  async reloadPersonen(): Promise<void> {
    this.allPersons = await PersonService.getAll(true);
  }

  async reloadSocieties(): Promise<void> {
    this.allSocieties = await SocietyService.getAll(true);
  }

  handleBack(): void {
    this.loading = false;
    this.$router.back();
  }

  getFullName(person: Person): string {
    return new Person(person).getFullname();
  }

  getSocietyName(society: Society): string {
    society = new Society(society);
    return society.name;
  }

  loadSporteventCompetitions(person: Person): void {
    person = new Person(person);
    if (person.defaultSocietyId != undefined) {
      SocietyService.get(person.defaultSocietyId).then((society: Society) => {
        this.starter.startsForSociety = society;
      });
    }
    const sportevent = SporteventService.get();
    if (sportevent.id !== undefined) {
      SporteventCompetitionService.getByAgeGender(
        sportevent.id,
        person.getAge(),
        person.gender
      ).then((items: SporteventCompetition[] | undefined) => {
        if (items === undefined) return;
        this.allSporteventCompetitions = items.filter(
          (sc) => sc.competition.isTeamCompetition === false
        );

        const competitionALL = new Competition();
        competitionALL.id = "all";
        competitionALL.name =
          "-- Person zu allen Bewerben seines Jahrgangs hinzufügen --";
        const sporteventCompetitionALL = new SporteventCompetition();
        sporteventCompetitionALL.id = "all";
        sporteventCompetitionALL.competition = competitionALL;
        this.allSporteventCompetitions.push(sporteventCompetitionALL);
        this.allSporteventCompetitions.sort((a, b) => {
          if (a.competition.name < b.competition.name) {
            return -1;
          }
          if (a.competition.name > b.competition.name) {
            return 1;
          }
          return 0;
        });
        console.log(this.allSporteventCompetitions);
      });
    }
  }

  getSporteventCompetitionName(
    sporteventCompetition: SporteventCompetition
  ): string {
    sporteventCompetition = new SporteventCompetition(sporteventCompetition);
    //console.log("test", sporteventCompetition);
    return sporteventCompetition.competition.name;
  }
}
